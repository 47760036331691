/* eslint-disable no-useless-escape */
export const validateEmail = (input: string) => {
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (re.test(input)) {
      return true;
    }
    return false;
};

export const validateWithNumber = (input: string) => {
  const re = /^(?=.*[0-9]).*$/;
  if (re.test(input)) {
    return true;
  }
  return false;
};

export const validateWithUpperOrLowerCase = (input: string) => {
  const re = /^(?=.*[A-Z])(?=.*[a-z]).*$/;
  if (re.test(input)) {
    return true;
  }
  return false;
};

export const validateSpecialCharacter = (input: string) => {
  const re = /^(?=[^#?!@$%^&*\n-]*[#?!@$%^&*-]).*$/;
  if (re.test(input)) {
    return true;
  }
  return false;
};

export const onlyNumberKey = (e: any) => {
	const ASCIICode = (e.which) ? e.which : e.keyCode
	if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
		return e.preventDefault();
	}
	return true;
}