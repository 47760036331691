/* eslint-disable array-callback-return */
import { STATUS } from "@constant";
import { GenerateStatusDesign } from "./GenerateStatus";

export const COLUMNS = [
  {
    accessor: "orderNumber",
    Header: "Order Number",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <div className="flex justify-center">
          <span className="text-center">#{d?.orderNumber}</span>
        </div>
      );
    },
  },
  {
    accessor: "name",
    Header: "Customer",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const customer = d?.order?.customer?.default_address;
      return (
        <div className="pl-10 align-center max-w-[250px]">
          <p className="text-center capitalize">{customer?.name}</p>
          <p className="text-center capitalize text-grey-1">
            {customer?.phone}
          </p>
        </div>
      );
    },
  },
  {
    accessor: "default_address",
    Header: "DropOff Address",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const customer = d?.order?.customer?.default_address;
      const dropOff = [
        `${customer?.address1}, 
        ${customer?.city}, ${customer?.province}, ${customer?.zip}`,
      ]
        .filter(Boolean)
        .join(" ");
      return (
        <div className=" pl-10 max-w-[250px]">
          <span className="text-left capitalize">{dropOff}</span>
        </div>
      );
    },
  },
  {
    accessor: "line_items",
    Header: "Item(s)",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      const orderItems = d?.order?.line_items;

      return (
        <>
          {orderItems?.map((item) => (
            <p className="text-center capitalize">{item?.name}</p>
          ))}
        </>
      );
    },
  },
  {
    accessor: "status",
    Header: "Status",
    isVisible: true,
    Cell: (data) => {
      const d = data?.row?.original;
      return (
        <GenerateStatusDesign status={d?.status} label={STATUS[d?.status]} />
      );
    },
  },
];
