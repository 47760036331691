/* eslint-disable react-hooks/exhaustive-deps */
import { Button, ModalContainer, Span, Inputs } from "@common";
import { InputContainerNew, Loader } from "@components";
import { onlyNumberKey } from "@utils";
import { Form } from "antd";
import { useEffect, useState } from "react";
import Autocomplete from "./Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import {
  deletePickupLocation,
  getPickupById,
  updatePickupLocation,
} from "@api";
import { useHistory } from "react-router-dom";

type Props = {
  id: string;
  getPickupLocation: Function;
};

export const UpdatePickupLocationModal = ({ id, getPickupLocation }: Props) => {
  const [form]: any = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  const pickup = useSelector((state: RootState) => state.Ecommerce);
  const [pickupLocationAdd, setPickupLocation] = useState() as any;
  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setPickupLocation({
      ...pickupLocationAdd,
      [name]: value,
    });
  };

  const getPickupLocationById = async () => {
    setisloading(true);
    try {
      const res = await getPickupById(id);
      setPickupDetails(res);
      dispatch.Ecommerce.updateState({
        address: res?.address,
      });
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message,
        type: "Error",
      });
    }
    setisloading(false);
  };

  useEffect(() => {
    getPickupLocationById();
  }, []);

  const handleUpdatePickupLocation = async () => {
    try {
      const { ...pickupLocationAdd } = await form.validateFields();
      const res = await updatePickupLocation(id, {
        ...pickupLocationAdd,
        location: {
          lat: pickup?.location?.lat,
          lng: pickup?.location?.lng,
        },
        address: pickup?.address,
        contactNumber: `+63${pickupLocationAdd?.contactNumber}`,
      });
      if (res) {
        dispatch.UI.setAlert({
          message: "Pickup Location successfully updated!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message: err?.response?.data?.message ?? "Unable to add team member!",
        type: "Error",
      });
    }

    dispatch.Ecommerce.updateState({
      address: "",
      location: {
        lat: 0,
        lng: 0,
      },
      pickUpId: "",
    });

    history.push(`/ecommerce/pickup-location`);
    getPickupLocation();
  };

  const handleDelete = async () => {
    try {
      const res = await deletePickupLocation(id);
      if (res) {
        dispatch.UI.setAlert({
          message: "Pickup Location deleted!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message:
          err?.response?.data?.message ?? "Unable delete pickup location!",
        type: "Error",
      });
    }
    history.push(`/ecommerce/pickup-location`);
    getPickupLocation();
  };

  const [pickupDetails, setPickupDetails] = useState({}) as any;
  const [isLoading, setisloading] = useState(false);

  useEffect(() => {
    const details = {
      id: pickupDetails?.id,
      storeName: pickupDetails?.storeName,
      contactName: pickupDetails?.contactName,
      contactNumber: pickupDetails?.contactNumber?.replace("+63", ""),
    };
    setPickupDetails(details);
    form.setFieldsValue({
      ...details,
    });
  }, [pickupDetails?.id]);

  return (
    <>
      <ModalContainer>
        <div className="p-10 py-5 whitespace-normal text-center ">
          <div className="mx-auto my-4 ">
            <Span color="orange" size="base" weight="semi">
              Pickup Location
            </Span>
          </div>

          {!isLoading ? (
            <Form layout="vertical" form={form}>
              <InputContainerNew
                icon={false}
                label="Store Name"
                required={true}
              >
                <Form.Item
                  name="storeName"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Store Name is required",
                    },
                  ]}
                >
                  <Inputs
                    padding="left"
                    border="bottom"
                    name="storeName"
                    type="text"
                    transform="capitalize"
                    onChange={onChange}
                  />
                </Form.Item>
              </InputContainerNew>

              <InputContainerNew
                icon={false}
                label="Contact Name"
                required={true}
              >
                <Form.Item
                  name="contactName"
                  className="w-full"
                  rules={[
                    {
                      required: true,
                      message: "Contact Name is required",
                    },
                  ]}
                >
                  <Inputs
                    padding="left"
                    border="bottom"
                    name="contactName"
                    type="text"
                    transform="capitalize"
                    onChange={onChange}
                  />
                </Form.Item>
              </InputContainerNew>

              <InputContainerNew
                icon={false}
                label="Contact Number"
                required={true}
              >
                <div className="flex w-full">
                  <span className="absolute border-b border-grey-2 py-2 text-xs">
                    (+63)
                  </span>
                  <Form.Item
                    name="contactNumber"
                    rules={[
                      {
                        required: true,
                        message: "Contact Number is required",
                      },
                    ]}
                    className="w-full"
                  >
                    <Inputs
                      padding="both"
                      border="bottom"
                      name="contactNumber"
                      type="phone"
                      onChange={onChange}
                      maxLength={10}
                      onKeyPress={onlyNumberKey}
                    />
                  </Form.Item>
                </div>
              </InputContainerNew>

              <InputContainerNew icon={false} label="Address" required={true}>
                <Autocomplete />
              </InputContainerNew>

              <div className="flex justify-between">
                <Button
                  bg="white"
                  border="grey"
                  color="grey"
                  width="none"
                  padding="px-6"
                  onClick={handleDelete}
                >
                  Remove
                </Button>

                <div className="flex justify-end">
                  <Button
                    bg="white"
                    color="grey"
                    width="none"
                    margin="m-4"
                    onClick={() => {
                      history.push(`/ecommerce/pickup-location`);
                      dispatch.Ecommerce.updateState({
                        address: "",
                        location: {
                          lat: 0,
                          lng: 0,
                        },
                        pickUpId: "",
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    bg="orange"
                    width="none"
                    padding="px-6"
                    onClick={handleUpdatePickupLocation}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </Form>
          ) : (
            <div className="flex justify-center bg-white p-44">
              <Loader />
            </div>
          )}
        </div>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
