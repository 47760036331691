/* eslint-disable no-undef */
import axios from "axios";
import { store } from "@store";  
 
const getUrl = (endpoint) => {
  const url =
    endpoint.indexOf("http://") === 0 || endpoint.indexOf("https://") === 0
      ? endpoint
      : process.env.REACT_APP_API_URL + endpoint;

  return url;
};

export const constructHeader = (token = store.getState().User.accessToken) => {
  return token ? { Authorization: "Bearer " + token } : {};
};

const coreFunc = (verb, endpoint, config = {}, data = null) => {
  config.headers = {
    ...config.headers,
    ...constructHeader(),
  };

  if (data !== null) {
    return axios[verb](getUrl(endpoint), data, config);
  }
  return axios[verb](getUrl(endpoint), config);
};

export const post = async (endpoint, data, headers = {}) =>
coreFunc(
  "post",
  endpoint,
  { headers: { "Content-Type": "application/json", ...headers } },
  JSON.stringify(data)
);

export const patch = async (endpoint, data, headers = {}) =>
coreFunc(
  "patch",
  endpoint,
  { headers: { "Content-Type": "application/json", ...headers } },
  JSON.stringify(data)
);

export const put = async (endpoint, data, headers = {}) =>
coreFunc(
  "put",
  endpoint,
  { headers: { "Content-Type": "application/json", ...headers } },
  JSON.stringify(data)
);

export const postFormData = async (endpoint, data) =>
coreFunc(
  "post",
  endpoint,
  { headers: { "Content-Type": "application/x-www-form-urlencoded" } },
  data
);

export const get = async (endpoint, headers = {}) =>
coreFunc("get", endpoint, {
  headers: { "Content-Type": "application/json", ...headers },
});

export const getBlob = async (endpoint, data, headers = {}) =>
coreFunc(
  "post", 
  endpoint, 
  { responseType: "blob", headers: { "Content-Type": "application/json", ...headers } },
  JSON.stringify(data)
);

export const getBlobv2 = async (endpoint, data, headers = {}) =>
coreFunc(
  "get", 
  endpoint, 
  { responseType: "blob", headers: { "Content-Type": "application/json", ...headers } },
  JSON.stringify(data)
);
  
export const postMultipart = async (endpoint, data) =>
coreFunc(
  "post",
  endpoint,
  { headers: { "Content-Type": "multipart/form-data" } },
  data
);

export const putMultipart = async (endpoint, data) =>
coreFunc(
  "put",
  endpoint,
  { headers: { "Content-Type": "multipart/form-data" } },
  data
);

export const remove = async (endpoint, data, headers = {}) =>
coreFunc(
  "delete",
  endpoint,
  { headers: { "Content-Type": "application/json", ...headers } },
  JSON.stringify(data),
);
