import { IEcommerceInfo, IPickupInfo, IQouteInfo } from "@types";
import { put, get, post, remove } from "./core";
import { store } from "@store";

const token = store.getState().User.accessToken;
const headers = {
  'Authorization': `Bearer ${token}`,
}; 

export const addShopifyInfo = async (shopifyInfo: IEcommerceInfo) => {
  try {
    let res = await post(`/merchant-ecommerce/shopify`, shopifyInfo);  
    return res.data; 
  } catch (err) {
    throw err;
  }
};

export const getListEcommerce = async () => {
  try {
    let res = await get(`/merchant-ecommerce`);   
    return res.data; 
  } catch (err) {
    throw err;
  }
};

export const getEcomyById = async (id: any) => {
  try {
    let res = await get(`/merchant-ecommerce/get-store-by-id/${id}`);   
    return res.data; 
  } catch (err) {
    throw err;
  }
};

export const updateEcomm = async (ecom: Partial<IEcommerceInfo>) => {
  try {
    let res = await put(`/merchant-ecommerce/shopify/${ecom?.id}`, ecom);  
    return res.data; 
  } catch (err) {
    throw err;
  }
};

export const deleteEcomm = async (ecom: IEcommerceInfo) => {
  try {
    let res = await remove(`/merchant-ecommerce/delete-store/${ecom?.id}`, ecom?.storeName);  
    return res.data; 
  } catch (err) {
    throw err;
  }
};



// PickupLocation
export const createPickupLocation = async (pickupLocation: IPickupInfo) => {
  try {
    let res = await post(`/merchant-pickup-location`, pickupLocation);  
    return res.data; 
  } catch (err) {
    throw err;
  }
};


export const getListPickupLocation = async () => {
  try {
    let res = await get(`/merchant-pickup-location`);   
    return res.data; 
  } catch (err) {
    throw err;
  }
};

export const deletePickupLocation = async (id: string) => {
  try {
    let res = await remove(`/merchant-pickup-location/delete-by-id/${id}`);  
    return res.data; 
  } catch (err) {
    throw err;
  }
};

export const getPickupById = async (id: any) => {
  try {
    let res = await get(`/merchant-pickup-location/get-by-id/${id}`);   
    return res.data; 
  } catch (err) {
    throw err;
  }
};

export const updatePickupLocation = async (id:string, pickupLocation: IPickupInfo) => {
  try {
    let res = await put(`/merchant-pickup-location/${id}`, pickupLocation);  
    return res.data; 
  } catch (err) {
    throw err;
  }
};  

export const getQoute = async (qoute: IQouteInfo) => {
  try {
    let res = await post(`/merchant-express-orders/quote`, qoute);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};


export const getVehicleType = async () => { 
  try {
    const res = await get(`/configures/vehicle-type`);    
    return res.data;
  } catch (err) {
      throw err;
  }
};


export const createOrder = async (orderId: any) => {
  try {
    let res = await post(`/merchant-express-orders`, orderId);      
    return res.data; 
  } catch (err) {
    throw err;
  }
};

// Sync 


export const syncOrder = async () => {
  try {
    let res = await post(`/merchant-orders/sync-ecomm-orders`, { headers });      
    return res.data; 
  } catch (err) {
    throw err;
  } 
};
