export const GenerateStatusDesign = ({ status, label }: any) => {
  const renderDesign = () => {
    switch (status) {
      case "new":
        return (
          <div className="bg-[#f6ffed] border-[#b7eb8f] ecom-status">
            <span className="text-xs text-[#389e0d] p-1">{label}</span>
          </div>
        );
      case "quoted":
        return (
          <div className="bg-[#f6ffed] border-[#b7eb8f] ecom-status">
            <span className="text-xs text-[#389e0d] p-1">{label}</span>
          </div>
        );
      case "created":
        return (
          <div className="bg-[#f0f5ff] border-[#adc6ff] ecom-status">
            <span className="text-xs text-[#1d39c4] p-1">{label}</span>
          </div>
        );
      case "ongoing_delivery":
        return (
          <div className="bg-[#fff4ec] border-[#fe933b] ecom-status">
            <span className="text-xs text-[#e06602] p-1">{label}</span>
          </div>
        );
      case "delivered":
        return (
          <div className="bg-[#f6ffed] border-[#b7eb8f] ecom-status">
            <span className="text-xs  text-[#389e0d] p-1">{label}</span>
          </div>
        );
      case "canceled":
        return (
          <div className="bg-[#fff1f0] border-[#ffa39e] ecom-status">
            <span className="text-xs text-[#cf1322] p-1">{label}</span>
          </div>
        );
      default:
        return (
          <div className="border-[1px] border-[#b7eb8f] rounded-sm w-[100px] bg-[#f6ffed] flex items-center justify-center">
            <span className="text-xs text-[#389e0d] p-1">{label}</span>
          </div>
        );
    }
  };
  return renderDesign();
};
