import { Button, ModalContainer, Span, Inputs } from "@common";
import { InputContainerNew } from "@components";
import { onlyNumberKey } from "@utils";
import { Form } from "antd";
import { useState } from "react";
import Autocomplete from "./Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "@store";
import { createPickupLocation } from "@api";

type Props = {
  setPickupLocationModal: Function;
  getPickupLocation: Function;
};

export const PickupLocationModal = ({
  setPickupLocationModal,
  getPickupLocation,
}: Props) => {
  const [form]: any = Form.useForm();
  const dispatch = useDispatch<Dispatch>();

  const pickup = useSelector((state: RootState) => state.Ecommerce);
  const [pickupLocationAdd, setPickupLocation] = useState() as any;
  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setPickupLocation({
      ...pickupLocationAdd,
      [name]: value,
    });
  };

  const handleAddPickupLocation = async () => {
    try {
      const { ...pickupLocationAdd } = await form.validateFields();
      const res = await createPickupLocation({
        ...pickupLocationAdd,
        location: {
          address: pickupLocationAdd?.locationDetails,
          lat: pickup?.location?.lat,
          lng: pickup?.location?.lng,
        },
        address: pickup?.address,
        contactNumber: `+63${pickupLocationAdd?.contactNumber}`,
      });
      if (res) {
        dispatch.UI.setAlert({
          message: "Pickup Location successfully added!",
          type: "Success",
        });
      }
    } catch (err: any) {
      dispatch.UI.setAlert({
        message:
          err?.response?.data?.message ?? "Unable to add pickup location!",
        type: "Error",
      });
    }

    setPickupLocationModal(false);
    dispatch.Ecommerce.updateState({
      address: "",
      location: {
        lat: 0,
        lng: 0,
      },
      pickUpId: "",
    });

    getPickupLocation();
  };

  return (
    <>
      <ModalContainer>
        <div className="p-10 py-5 whitespace-normal text-center ">
          <div className="mx-auto my-4 ">
            <Span color="orange" size="base" weight="semi">
              Pickup Location
            </Span>
          </div>

          <Form layout="vertical" form={form}>
            <InputContainerNew icon={false} label="Store Name" required={true}>
              <Form.Item
                name="storeName"
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Store Name is required",
                  },
                ]}
              >
                <Inputs
                  padding="left"
                  border="bottom"
                  name="storeName"
                  type="text"
                  transform="capitalize"
                  onChange={onChange}
                />
              </Form.Item>
            </InputContainerNew>

            <InputContainerNew
              icon={false}
              label="Contact Name"
              required={true}
            >
              <Form.Item
                name="contactName"
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Contact Name is required",
                  },
                ]}
              >
                <Inputs
                  padding="left"
                  border="bottom"
                  name="contactName"
                  type="text"
                  transform="capitalize"
                  onChange={onChange}
                />
              </Form.Item>
            </InputContainerNew>

            <InputContainerNew
              icon={false}
              label="Contact Number"
              required={true}
            >
              <div className="flex w-full">
                <span className="absolute border-b border-grey-2 py-2 text-xs">
                  (+63)
                </span>
                <Form.Item
                  name="contactNumber"
                  rules={[
                    {
                      required: true,
                      message: "Contact Number is required",
                    },
                  ]}
                  className="w-full"
                >
                  <Inputs
                    padding="both"
                    border="bottom"
                    name="contactNumber"
                    type="phone"
                    onChange={onChange}
                    maxLength={10}
                    onKeyPress={onlyNumberKey}
                  />
                </Form.Item>
              </div>
            </InputContainerNew>

            <InputContainerNew
              icon={false}
              label="Location Details"
              required={true}
            >
              <Form.Item
                name="locationDetails"
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Location Details is required",
                  },
                ]}
              >
                <Inputs
                  padding="left"
                  border="bottom"
                  name="locationDetails"
                  type="text"
                  transform="capitalize"
                  onChange={onChange}
                />
              </Form.Item>
            </InputContainerNew>

            <InputContainerNew icon={false} label="Address" required={true}>
              <Autocomplete />
            </InputContainerNew>

            <div className="flex justify-end">
              <Button
                bg="white"
                color="grey"
                width="none"
                margin="m-4"
                onClick={() => {
                  setPickupLocationModal(false);
                  dispatch.Ecommerce.updateState({
                    address: "",
                    location: {
                      lat: 0,
                      lng: 0,
                    },
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                bg="orange"
                width="none"
                padding="px-6"
                onClick={handleAddPickupLocation}
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </ModalContainer>

      <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
