import * as React from "react";
import classnames from "classnames";

type Props = {
  children: React.ReactNode;
  className?: string;
  flex?: "flex" | "wrap";
  margin?: "my-8" | "mt-2" | "mt-4" | "none" | "auto";
  align?: "center";
  grid?: "three" | "four";
  padding?: "p-4" | "px-2" | "pl-4" | "p-2";
  height?: "none" | "h-48" | "h-50" | "h-60";
  bg?: "transparent" | "grey";
  width?: "medium";
};

let getFlex = (flex: Props["flex"]) => {
  switch (flex) {
    case "wrap":
      return "flex flex-wrap";
    case "flex":
      return "flex";
    default:
      return "flex-none";
  }
};

let getMargin = (margin: Props["margin"]) => {
  switch (margin) {
    case "my-8":
      return "my-8";
    case "mt-2":
      return "mt-2";
    case "mt-4":
      return "mt-4";
    case "none":
      return "none";
    case "auto":
      return "m-auto";
    default:
      return "my-2";
  }
};

let getAlign = (align: Props["align"]) => {
  switch (align) {
    case "center":
      return "text-center";
    default:
      return "text-left";
  }
};

let getGrid = (grid: Props["grid"]) => {
  switch (grid) {
    case "four":
      return "lg:grid-cols-4 md:grid-cols-2";
    case "three":
      return "lg:grid-cols-3 md:grid-cols-3";
    default:
      return "lg:grid-cols-2 md:grid-cols-2";
  }
};

let getPadding = (padding: Props["padding"]) => {
  switch (padding) {
    case "pl-4":
      return "pl-4";
    case "p-2":
      return "p-2";
    case "p-4":
      return "p-4";
    case "px-2":
      return "px-2";
    default:
      return "p-0";
  }
};

let getHeight = (height: Props["height"]) => {
  switch (height) {
    case "h-50":
      return "h-50";
    case "h-60":
      return "h-60";
    case "h-48":
      return "h-48";
    case "none":
      return "h-none";
    default:
      return "h-36";
  }
};
let getBg = (bg: Props["bg"]) => {
  switch (bg) {
    case "transparent":
      return "bg-transparent";
    case "grey":
      return "bg-grey-5";
    default:
      return "bg-white";
  }
};

let getWidth = (width: Props["width"]) => {
  switch (width) {
    // case "large":
    //   return "w-8/12";
    // case "medium":
    //   return "w-6/12";
    case "medium":
      return "lg:w-5/12 md:w-11/12";
    default:
      return "lg:w-4/12 md:w-11/12";
  }
};

export const HeaderContainer = ({ children }: Props) => {
  return (
    <div className="header">
      <div className="header-sub">{children}</div>
    </div>
  );
};

export const MainContainer = ({ children }: Props) => {
  return (
    <div className={classnames("main-content")}>
      <main>
        <div className="layout">
          <div className="layout-content">
            <div className="layout-content-main">{children}</div>
          </div>
        </div>
      </main>
    </div>
  );
};

export const CardContainer = ({ children }: Props) => {
  return (
    <div className="min-h-screen overflow-hidden">
      <div className="flex min-h-screen justify-center items-center">
        <div className="card-center">{children}</div>
      </div>
    </div>
  );
};

export const Container = ({
  flex,
  margin,
  children,
  align,
  padding,
  bg,
  className,
}: Props) => {
  return (
    <div
      className={classnames(
        getBg(bg),
        getFlex(flex),
        getMargin(margin),
        getAlign(align),
        getPadding(padding),
        className
      )}
    >
      {children}
    </div>
  );
};

export const GridContainer = ({ grid, children }: Props) => {
  return (
    <div
      className={classnames(
        "grid md:grid-cols-1 w-full gap-5 md:gap-2",
        getGrid(grid)
      )}
    >
      {children}
    </div>
  );
};

export const SubContainer = ({ height, padding, margin, children }: Props) => {
  return (
    <div
      className={classnames(
        "flex border border-grey-3 bg-white",
        getHeight(height)
      )}
    >
      <div
        className={classnames(
          "flex justify-center w-full",
          getPadding(padding),
          getMargin(margin)
        )}
      >
        {children}
      </div>
    </div>
  );
};

export const ModalContainer = ({ bg, width, children }: Props) => {
  return (
    <div
      className={
        "flex fixed inset-0 z-50 outline-none focus:outline-none items-center justify-center"
      }
    >
      <div className={classnames(getWidth(width))}>
        <div
          className={classnames(
            "shadow-lg relative flex flex-col w-full outline-none focus:outline-none rounded-sm",

            getBg(bg)
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export const QuantityContainer = ({ bg, margin, children }: Props) => {
  return (
    <div
      className={classnames(
        "text-black font-bold flex",
        getBg(bg),
        getMargin(margin)
      )}
    >
      {children}
    </div>
  );
};
