/* eslint-disable @typescript-eslint/no-unused-vars */
import { BiSortDown, BiSortUp, CgChevronLeft, CgChevronRight } from "@icons";
import { useTable, useSortBy, usePagination } from "react-table";
import "./Table.scss";
import { Button, Span } from "@common";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Dispatch } from "@store";

type Props = {
  data: any;
  columns: any;
  dataType?: string;
  tableClass: string;
};

export const TableWithPagination = ({
  columns,
  data,
  dataType,
  tableClass,
}: Props) => {
  const dispatch = useDispatch<Dispatch>();
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // @ts-ignore
    page,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    setPageSize,
    state,
    prepareRow,
  } = tableInstance;

  const { pageIndex, pageSize }: any = state;

  const history = useHistory();
  const handleRowClick = (row: any) => {
    switch (dataType) {
      case "ecom-pickup":
        history.push(`/ecommerce/pickup-location/${row?.original?.id}`);
        break;
      case "purchase-view":
        history.push(`/orders/purchase/${row?.original?.id}`);
        break;
      case "customer-order-view":
        history.push(`/orders/customer-order/view/${row?.original?.id}`);
        break;
      case "choose-supplier":
        dispatch.Supplier.updateState({
          storeName: row?.original?.storeName,
        });
        break;
      default:
        return "";
    }
  };

  return (
    <>
      <div className={tableClass}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="flex whitespace-nowrap font-normal table-header justify-center font-bold">
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <BiSortDown className="text-lg mx-2" />
                          ) : (
                            <BiSortUp className="text-lg mx-2" />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        onClick={() => {
                          handleRowClick(row);
                        }}
                        {...cell.getCellProps()}
                        className="cursor-pointer"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination-team">
        <Button
          bg="white"
          color="black"
          margin="none"
          width="none"
          border="grey"
          padding="p-2"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <CgChevronLeft size={16} />
        </Button>
        <div className="my-auto mx-2 flex border border-grey-4 py-2 px-4 rounded-md">
          <Span margin="auto">Page</Span>
          <span className="gotoPage">{pageIndex + 1}</span>
          <Span margin="auto"> of {pageOptions.length}</Span>
        </div>
        <Button
          bg="white"
          margin="none"
          width="none"
          color="black"
          border="grey"
          padding="p-2"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <CgChevronRight size={16} />
        </Button>

        <div className="mx-2 flex border border-grey-4 py-2 px-4 rounded-md">
          <Span margin="auto">Rows per page:</Span>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="rowsperpage"
          >
            {[10, 20].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};
